:root {
  /* Route Colors - Red */
  --red-50: #fef2f2;
  --red-200: #fecaca;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;

  /* Route Colors - Blue */
  --blue-50: #eff6ff;
  --blue-200: #bfdbfe;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;

  /* Route Colors - Green */
  --green-50: #f0fdf4;
  --green-200: #bbf7d0;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;

  /* Route Colors - Yellow */
  --yellow-50: #fefce8;
  --yellow-200: #fef08a;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;

  /* Route Colors - Gray (fallback) */
  --gray-50: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Add to your global CSS */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent !important;
}

/* For Firefox */
input {
  background-color: transparent !important;
}

/* For Safari */
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
